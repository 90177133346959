import React from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
// import { connect } from 'react-redux';

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { navigate, Link } from "gatsby"
import SEO from "../components/seo"

// actions
// import { loginUser,apiError } from '../../store/actions';

// import images
// import logo from "../../assets/images/logo.svg";

export default function Login(props) {
    const handleSubmit = ()=>{
        navigate("/")
    }
  return (
    <React.Fragment>
        <SEO/>
      <div className="home-btn d-none d-sm-block">
        {/* <a to="/" className="text-dark"><i className="bx bx-home h2"></i></a> */}
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="d-flex justify-content-center">
                <Link to="/">
                  <div className="avatar-md profile-user-wid mb-4">
                    <span className="avatar-title rounded-circle bg-light">
                      <img
                        src="/images/paraffin-logo.png"
                        alt=""
                        className="rounded-circle"
                        height="54"
                      />
                    </span>
                  </div>
                </Link>
              </div>
              <Card className="overflow-hidden">
                <div>
                  <Row>
                    <Col className="col-8">
                      <div className="text-primary p-2">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to ParaffinIoT</p>
                      </div>
                    </Col>
                    {/* <Col className="col-4 align-self-end">
                      <img
                        src="/images/profile-img.png"
                        alt="Profile"
                        className="img-fluid"
                      />
                    </Col> */}
                  </Row>
                </div>
                <CardBody className="pt-2">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={handleSubmit}
                    >
                      {props.error && props.error ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          value="admin@paraffinIoT.com"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          value="123456"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customControlInline"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                      <Link to="/forgot-password" className="text-muted"><i className="bx bx-lock-alt mr-1"></i> Forgot your password?</Link>

                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don't have an account ?{" "}
                  <Link
                    to="/register"
                    className="font-weight-medium text-primary"
                  >
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
